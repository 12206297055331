.navbar-box {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  position: fixed;
  
  z-index: 99;
}

.navbar-container {
  width: 90%;
  height: 90%;
  border-radius: 50px;
  position: relative;
}

.navbar-left-container {
  width: 75%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
}

.navbar-icon-box {
  width: 55px;
  height: 55px;
  background-color: #1b294b;
  border-radius: 50%;
  position: absolute;
  
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar-right-container {
  width: 25%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
}

.nav-links {
  width: 100%;
}

.nav-links ul li a {
  color: black;
  font-family: "Poppins", "sans-serif";
  
  text-transform: uppercase;

}

.nav-links ul {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}



/* Keep the navbar at the top when scrolling */
.navbar-scroll {
  position: fixed;
  transform: translateY(-100%);
  width: 100%;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  transition: all 0.4s ease-in-out;
}


.active-link::after {
  content: "";
  width: 10px;
  height: 10px;
  background-color: #007bff; /* Set the color of the indicator */
  border-radius: 50%;
  position: absolute;
  bottom: -5px; /* Adjust the position to align it under the link */
  left: 50%;
  transform: translateX(-50%); /* Center the indicator horizontally under the link */
  transition: width 0.3s ease-in-out; /* Add a transition for a smoother effect */
}

