.navbar-box {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  position: fixed;
  top: 0;
  z-index: 99;
  transition: transform 0.4s ease-in-out;
  
}

/* Style for the sticky navbar */
/* .navbar-scroll {
    transform: translateY(-100%);
  width: 100%;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
} */


