.driver-wrapper {
    width: 100%;
    height:100%;
}

.d-left-cntd {
    
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1rem ;
    flex-direction: column;
    row-gap: 2rem;
    background-image: url(../../Assets/driverPage.png);
    background-position: 'center';
    background-size: cover;
    position: relative;
    
}
/* .d-right-cntd {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
} */