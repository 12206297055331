.mySwiper {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 400px;
}

.sliders {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #20364b;
}