



.sf-img-top-cntd img {
    width: 100%;
    object-fit: contain;
}
.sf-top-content {

border-bottom-left-radius: 10%;
border-top-left-radius: 20%;
border-bottom-right-radius: 10px;
border-top-right-radius: 10px;
border:1px solid #fcd12a;

}


.sf-bottom-grid {
    height: 450px;
    display: flex;
    align-items: center;
    justify-content: center;    
}

.sf-img-bottom-cntd {
    width: 500px;
    object-fit: contain;
    position:absolute;
    right: 10%;
}

.sf-img-bottom-cntd img {
    width: 100%;
    object-fit: contain;
}

.sf-bottom-content {
    width: 60%;
    height: 90%;
    border-bottom-right-radius: 10%;
    border-top-right-radius: 20%;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    border:1px solid #fcd12a;
    }