.feature-header {
    width: 100%;
    height: 100px;
    
}


.f-grid {
    
    height: fit-content;
}

.f-grid-logo-cntd {
    display: flex;
    align-items: center;
    justify-content: center;
}

.f-grid-img {
    width: 70px;
    height: 70px;
}

.f-grid-img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.f-grid-cntd {
   height: 70%;
    padding: 1rem 1.5rem;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}