.service-header {
    width: 100%;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    
}

.service-grid-item {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    
}
 
.s-grid-img {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}

.s-grid-img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 1rem;
    
}

.s-grid-content {
    /* position: absolute; */
    top: 60%;
    width: 80%;
    height: 250px;
    left: 10%;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}






  
  