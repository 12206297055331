.testimonials-body {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  
}

.tm-left-cntd {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding:0 10px ;
}
.tm-right-cntd {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  background-image: url(../../Assets/testimonialsBGR.jpg);
  background-position: center;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
 
}
.tm-carousel-content {
  width: 100%;
  height: 90%;
  padding: 1rem;
}

.carousel-body {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
}





