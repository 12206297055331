#Home {
    position: relative;

}

.home-body {
    width:100%;
    height:auto;
    object-fit: cover;
    overflow-y: hidden;
}

.home-logo-container {
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.home-logo-box {
    width: 8rem;
}

.home-logo-box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.home-content-highlighter {
    width: 100%;
    position: absolute;
    background-color: rgba(0,0,0,0.6);
    height: 100%;
    z-index: 2;
}

